import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const festivaltravel1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A Festival Travel-el egy olyan reszponzív webolda fejlesztését kaptuk feladatul, ahol a látogató különböző utazások és fesztiválok között tud válogatni, szállásokat foglalni, belépőket vásárolni.",
  displayXo: true,
  reverse: true,
  title: "Mindenkinek jár egy kis kikapcsolódás",
  picture: "/images/refs/festivaltravel/ft_card.png",
  boldDescription: ''
}



export const festivaltravelInfoCards = [
  festivaltravel1,
]

