import { Reference } from "../reference-interface"
import { festivaltravelInfoCards } from "../../service-info-card-interface/used_by/festivaltravel-cards"

export const Festivaltravel : Reference = {
  monitorPic: "/images/refs/festivaltravel/ft_mac.png",
  siteName: "Festival Travel",
  siteDescription: "Utazások, fesztivál belépők, síelés, minden egy felületen.",
  siteUrl: "https://festivaltravel.hu",
  cards: festivaltravelInfoCards,
  referencePics: [
    "/images/refs/festivaltravel/ft_screenshot_1.png",
    "/images/refs/festivaltravel/ft_screenshot_2.png",
    "/images/refs/festivaltravel/ft_screenshot_3.png",
  ],
  pageFunctions: [
    "Reszponzív design",
    "Utazás és foglalás",
  ],
  reversePageFunctions: false,
}
